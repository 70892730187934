'use client';

import { useState } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  IconType,
  breakpoints,
  useMediaQuery,
} from '@grupa-pracuj/ui-library-relax';
import { CurrentStepInAuthProcessPayload } from '@grupa-pracuj/tracker-relax/dist/events';

import { useAnalytics } from 'src/analytics';
import buildApplyUrl from 'src/utils/build-apply-url';
import getEmailOriginId from 'src/utils/get-email-origin-id';
import useQueryParameters from 'src/hooks/use-query-parameters/use-query-parameters';
import useOffer from 'src/context/OfferContext';
import { ExternalProvider } from './ExternalProvidersButtons.models';
import {
  facebookButtonText,
  facebookButtonDataTest,
  googleButtonText,
  googleButtonDataTest,
} from './ExternalProvidersButtons.constants';
import styles from './ExternalProvidersButtons.module.css';

function ExternalProvidersButtons() {
  const { returnUrl, offerId, applicationProcessId, oneClickApplyStatus, attachedCV, multiAtsId } =
    useQueryParameters();
  const { authProcessId, emailOriginIdParam, v2, v3 } = useAnalytics();
  const offer = useOffer();
  const [isDesktop] = useMediaQuery(breakpoints.desktop);
  const [providerAction, setProviderAction] = useState<ExternalProvider | null>(null);

  const buttonSize = isDesktop ? ButtonSize.L : ButtonSize.M;
  const isApplicationProcess = !!offerId && !!offer && !!applicationProcessId;
  const emailOriginId = isApplicationProcess ? getEmailOriginId(offer.offerApplyType) : emailOriginIdParam;
  const returnLink = isApplicationProcess
    ? buildApplyUrl({
        applicationProcessId,
        oneClickApplyStatus,
        attachedCV,
        multiAtsId,
        applyLink: offer.applyLink,
      })
    : returnUrl;

  const handleOnClickExternalProvider = (provider: ExternalProvider) => {
    setProviderAction(provider);

    const queryString = [
      `provider=${provider}`,
      `aupid=${authProcessId}`,
      offerId && `oid=${offerId}`,
      applicationProcessId && `apid=${applicationProcessId}`,
      attachedCV && `acv=${attachedCV}`,
      oneClickApplyStatus && `oca=${oneClickApplyStatus}`,
      emailOriginId && `emailOriginId=${emailOriginId}`,
      returnLink && `returnUrl=${encodeURIComponent(returnLink)}`,
    ]
      .filter(Boolean)
      .join('&');

    const redirectUrl = `/external-login?${queryString}`;

    const currentStepInAuthProcessPayload: CurrentStepInAuthProcessPayload = {
      authProcessId,
      processCurrentStage: 'start',
      accountType: provider.toLowerCase() as CurrentStepInAuthProcessPayload['accountType'],
      processType: 'unknown',
      isApplicationProcess,
    };

    const callback = () => {
      // window.location.assign(redirectUrl);
      v2.setTrackerCallback(() => window.location.assign(redirectUrl));
      v2.sendCurrentStepInAuthProcess(currentStepInAuthProcessPayload);
    };

    v3.sendCurrentStepInAuthProcess(currentStepInAuthProcessPayload, callback);
  };

  return (
    <div className={styles.externalProvidersButtons}>
      <Button
        dataTest={googleButtonDataTest}
        isLoading={providerAction === ExternalProvider.Google}
        onClick={() => handleOnClickExternalProvider(ExternalProvider.Google)}
        iconLeft={IconType.Google}
        variant={ButtonVariant.Secondary}
        wide
        size={buttonSize}
      >
        {googleButtonText}
      </Button>
      <Button
        dataTest={facebookButtonDataTest}
        isLoading={providerAction === ExternalProvider.Facebook}
        onClick={() => handleOnClickExternalProvider(ExternalProvider.Facebook)}
        iconLeft={IconType.Facebook}
        variant={ButtonVariant.Secondary}
        wide
        size={buttonSize}
      >
        {facebookButtonText}
      </Button>
      {/* Temporarily turned off */}
      {/* <Button
        dataTest={appleButtonDataTest}
        iconLeft={IconType.Apple}
        variant={ButtonVariant.Secondary}
        wide
        size={buttonSize}
      >
        {appleButtonText}
      </Button> */}
    </div>
  );
}

export default ExternalProvidersButtons;
