export enum GlobalError {
  // WelcomePage (error scope: 1 - 10)
  InvalidActivationLink = 1,
  AccountAlreadyConfirmed = 2,
  ExternalProviderError = 3,
  FacebookAccessDenied = 4,
  EmailsAccountConfirmationError = 5,
  InvalidPasswordResetLink = 6,
}

export enum EntryFeedback {
  emailChangedFailed = 'emailChangedFailed',
  emailChangedSuccess = 'emailChangedSuccess',
  passwordSettingFailed = 'passwordSettingFailed',
  passwordSettingSuccess = 'passwordSettingSuccess',
}

export interface GlobalErrorProps {
  globalError?: string | number;
  entryFeedback?: string;
}
