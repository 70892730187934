// 'use client';

import {
  defaultErrorMessage,
  emailValidationErrorMessage,
  missingTermsAndConditionCheckboxErrorMessage,
  passwordSettingForUserErrorMessage,
  passwordVaidationErrorMessage,
  userConfirmationFailureErrorMessage,
  userExistConfirationErrorMessage,
  userNotFoundErrorMessage,
} from './translate-response-error-message.constants';
import { ErrorStatusCode, ErrorType } from './translate-response-error-message.models';

export const getTranslatedErrorMessages = (status: number) =>
  new Map<ErrorStatusCode, string>([
    [ErrorStatusCode.PasswordValidationError, passwordVaidationErrorMessage],
    [ErrorStatusCode.PasswordSettingForUserError, passwordSettingForUserErrorMessage],
    [ErrorStatusCode.MissingTermsAndConditionCheckbox, missingTermsAndConditionCheckboxErrorMessage],
    [ErrorStatusCode.EmailValidationError, emailValidationErrorMessage],
    [ErrorStatusCode.UserConfirmationFailur, userConfirmationFailureErrorMessage],
    [ErrorStatusCode.UserNotFound, userNotFoundErrorMessage],
    [ErrorStatusCode.UserExistConfirmed, userExistConfirationErrorMessage],
    [ErrorStatusCode.UserExistNotConfirmed, userExistConfirationErrorMessage],
  ]).get(status) ?? defaultErrorMessage;

export default function translateResponseErrorMessage(responseError?: ErrorType | ErrorType[]): string {
  if (typeof responseError === 'object' && (responseError as ErrorType)?.statusCode) {
    const statusCode = (responseError as ErrorType).statusCode;

    return getTranslatedErrorMessages(statusCode);
  }

  if (Array.isArray(responseError)) {
    const statusCode = responseError.flat().find(Boolean)?.statusCode;

    return statusCode ? getTranslatedErrorMessages(statusCode) : defaultErrorMessage;
  }

  return defaultErrorMessage;
}
