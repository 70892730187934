export const defaultErrorMessage = 'Coś poszło nie tak.';
export const requiredErrorMessage = 'To pole jest wymagane.';
export const requiredAgreementErrorMessage = 'Zaakceptuj Regulamin, aby utworzyć konto';
export const passwordVaidationErrorMessage = 'Podane hasło nie spełnia reguł.';
export const passwordSettingForUserErrorMessage = 'Nie udało się ustawić hasła. Spróbuj ponownie.';
export const missingTermsAndConditionCheckboxErrorMessage = 'Regulamin nie został zaakceptowany.';
export const emailValidationErrorMessage = 'Niepoprawny format adresu e-mail.';
export const userConfirmationFailureErrorMessage = 'Nie udało się potwierdzić użytkownika. Spróbuj ponownie.';
export const userNotFoundErrorMessage = 'Nie znaleziono użytkownika o podanym adresie e-mail.';
export const userExistConfirationErrorMessage = 'Użytkownik o podanym adresie e-mail jest już zarejestrowany.';
