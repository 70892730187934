'use client';

import { AlertBox, AlertType } from '@grupa-pracuj/ui-library-relax';

import { EntryFeedback, GlobalError, GlobalErrorProps } from './GlobalAlert.models';

export const GlobalErrorsMessages = new Map<GlobalError, string>([
  [GlobalError.InvalidActivationLink, 'Ten link aktywacyjny jest nieprawidłowy lub stracił ważność.'],
  [GlobalError.AccountAlreadyConfirmed, 'To konto zostało już potwierdzone.'],
  [GlobalError.ExternalProviderError, 'Coś poszło nie tak, spróbuj ponownie.'],
  [GlobalError.FacebookAccessDenied, 'Brak dostępu do konta Facebook, spróbuj ponownie.'],
  [GlobalError.EmailsAccountConfirmationError, 'Coś poszło nie tak przy potwierdzaniu konta, spróbuj ponownie.'],
  [GlobalError.InvalidPasswordResetLink, 'Ten link aktywacyjny jest nieprawidłowy lub stracił ważność.'],
]);

export const EntryFeedbackMessages = new Map<EntryFeedback, string>([
  [
    EntryFeedback.emailChangedFailed,
    'Nie udało się zmienić Twojego emaila. Zaloguj się starym adresem i spróbuj ponownie.',
  ],
  [EntryFeedback.emailChangedSuccess, 'Twój email został zmieniony poprawnie. Zaloguj się ponownie!.'],
]);

function GlobalAlert({ globalError, entryFeedback }: GlobalErrorProps) {
  const codeNumber = typeof globalError !== 'number' ? Number(globalError) : globalError;
  const errorMessage = GlobalErrorsMessages.get(codeNumber);
  const entryFeedbackMessage = entryFeedback ? EntryFeedbackMessages.get(entryFeedback as EntryFeedback) : null;
  const entryAlertType = entryFeedback === EntryFeedback.emailChangedSuccess ? AlertType.Success : AlertType.Error;

  return (
    <>
      {errorMessage ? <AlertBox message={errorMessage} type={AlertType.Error} /> : null}
      {entryFeedbackMessage ? <AlertBox message={entryFeedbackMessage} type={entryAlertType} /> : null}
    </>
  );
}

export default GlobalAlert;
