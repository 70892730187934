import decodeString from 'src/utils/decode-string';
import { BuildApplyUrlProps } from './build-apply-url.models';

function buildApplyUrl({
  applicationProcessId,
  applyLink,
  oneClickApplyStatus,
  attachedCV,
  userEmail,
  userId,
  multiAtsId,
}: BuildApplyUrlProps): string {
  try {
    const url = new URL(applyLink);

    if (userEmail && userId) {
      url.searchParams.append('uat', decodeString({ text: `${userEmail}:${userId}`, from: 'ascii', to: 'base64' }));
      url.searchParams.append('awl', 'true');
      url.searchParams.append('loginSkipped', 'true');
    }

    if (multiAtsId) {
      url.searchParams.append('maid', multiAtsId);
    }

    url.searchParams.append('apid', applicationProcessId);
    url.searchParams.append('oca', oneClickApplyStatus ?? 'None');
    url.searchParams.append('acv', attachedCV ?? '0');

    return url.href;
  } catch {
    return applyLink;
  }
}

export default buildApplyUrl;
