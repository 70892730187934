import { OfferApplyType } from '../http-requests/get-offer';

export enum EmailOrigin {
  Facebook = 7,
  ApplyATS = 14,
  PracujRWD = 21,
  Apply = 23,
  ApplyOptionalCv = 43,
  Google = 47,
}

export type GetEmailOriginId = (offerApplyType?: {
  ats?: OfferApplyType | null;
  eRecruiter?: OfferApplyType | null;
  strefa?: OfferApplyType | null;
}) => EmailOrigin;
