import { CurrentStepInAuthProcessPayload } from '@grupa-pracuj/tracker-relax/dist/events';

import AppRoutes from 'src/app-routes.json';
import { Process } from 'src/utils/http-requests/get-auth-process';
import { QueryParameters } from 'src/hooks/use-query-parameters';

export type ParseNextStepRouteProps = {
  authProcessId: CurrentStepInAuthProcessPayload['authProcessId'];
  process: Process;
  createQueryString: QueryParameters['createQueryString'];
};

export default function parseNextStepRoute({
  process,
  authProcessId,
  createQueryString,
}: ParseNextStepRouteProps): string {
  const shouldTriggerMail = process === Process.Confirm || process === Process.ConfirmAndSetPassword;

  const queryString = [
    { name: 'aupid', value: authProcessId },
    shouldTriggerMail && { name: 'tm', value: 'true' },
  ].filter(Boolean) as { name: string; value: string }[];

  const pathName = [
    process === Process.Login && AppRoutes.LoginRoute.source,
    process === Process.Register && AppRoutes.RegisterRoute.source,
    process === Process.Confirm && AppRoutes.CheckEmailAndConfirmAccountRoute.source,
    process === Process.ConfirmAndSetPassword && AppRoutes.CheckEmailAndConfirmAccountAndSetPasswordRoute.source,
  ].find(Boolean);

  return `${pathName}?${createQueryString(queryString)}`;
}
