export enum ErrorStatusCode {
  PasswordValidationError = 40_013,
  PasswordSettingForUserError = 40_016,
  MissingTermsAndConditionCheckbox = 40_020,
  EmailValidationError = 40_034,
  UserConfirmationFailur = 40_041,
  UserNotFound = 40_400,
  UserExistConfirmed = 40_901,
  UserExistNotConfirmed = 40_902,
}

export type ErrorType = { statusCode: number; message: string };
