import { EmailOrigin, GetEmailOriginId } from './get-email-origin-id.models';

const getEmailOriginId: GetEmailOriginId = (offerApplyType) => {
  const { strefa, eRecruiter, ats } = offerApplyType ?? {};

  const origins = [
    strefa?.optionalCv && EmailOrigin.ApplyOptionalCv,
    strefa && EmailOrigin.Apply,
    (eRecruiter || ats) && EmailOrigin.ApplyATS,
  ] as EmailOrigin[];

  return origins.find(Boolean) ?? EmailOrigin.PracujRWD;
};

export default getEmailOriginId;
