'use client';

import React from 'react';
import Image from 'next/image';
import { HrSpace, JobOfferBox, Separator } from '@grupa-pracuj/ui-library-relax';

import { offerDataTest, offerInfoDataTest, offerLogoDataTest } from './OfferBox.constants';
import useOffer from 'src/context/OfferContext';

function OfferBox() {
  const offer = useOffer();

  if (!offer) {
    return null;
  }

  const { jobTitle, displayEmployerName, workplace, offerViewLogo } = offer;

  return (
    <>
      <JobOfferBox
        headerText={jobTitle}
        dataTest={offerDataTest}
        dataTestDescription={offerInfoDataTest}
        dataTestLogo={offerLogoDataTest}
        subheaderText={`${displayEmployerName}, ${workplace} `}
      >
        {offerViewLogo ? <Image src={offerViewLogo} width={62} height={62} alt="" quality={95} /> : undefined}
      </JobOfferBox>
      <Separator space={HrSpace.Small} />
    </>
  );
}

export default OfferBox;
