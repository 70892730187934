import { DecodeStringMockType } from '../decode-string.models';

export const decodeStringMock: DecodeStringMockType = {
  params: {
    text: 'UmVsYXggVGVhbSBpcyB0aGUgQkVTVCA8MyAtIEIuSw==',
    from: 'base64',
    to: 'ascii',
  },
  result: 'Relax Team is the BEST <3 - B.K',
};
